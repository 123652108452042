'use client';

import Link from 'next/link';
import React from 'react';

import Icon, { IconsNames } from '@/components/v3/Icon';
import { LinkProps } from '@/types/cms';
import { useCMSLink } from '@/hooks/useCMSLink';

interface Card {
  title: string;
  description: string;
  icon: IconsNames;
  link: LinkProps;
  linkLabel: string;
}

interface LinksSectionProps {
  referId: string;
  links: Card[];
}

export function LinksSection({ referId, links }: LinksSectionProps) {
  const { getHrefFromCMSParams } = useCMSLink();

  return (
    <section id={referId} className="bg-display-100">
      <div className="justify-center py-40 px-24 w-full bg-display-900 rounded-t-4xl">
        <div className="flex flex-col md:flex-row gap-40 md:gap-24 justify-center items-center md:items-stretch mx-auto w-full max-w-[1324px]">
          {links?.map(({ icon, title, description, link, linkLabel }) => (
            <div
              className="flex flex-col flex-1 justify-center p-16 md:p-24 w-full bg-display-800 rounded-[16px] border-2 border-display-700"
              key={`${title}-${description}`}
            >
              <Icon
                name={icon}
                className="w-40 h-40 text-stone-300 fill-current"
              />
              <h6 className="mt-8 font-display text-xl font-bold leading-5 text-display-0">
                {title}
              </h6>
              <p className="mt-4 text-display-0 paragraph-16">{description}</p>
              <Link
                legacyBehavior
                href={getHrefFromCMSParams(link)}
                aria-label={linkLabel}
              >
                <a className="flex items-center mt-16 md:mt-8 w-max font-medium text-stone-300 hover:text-stone-500">
                  <div className="flex items-center w-full border border-transparent hover:border-b-stone-500">
                    <p className="mr-4 font-medium text-current paragraph-16">
                      {linkLabel}
                    </p>

                    <Icon
                      name="arrow-up-right"
                      className="mx-auto w-24 h-24 fill-current"
                    />
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
