'use client';

import { useInView } from 'react-intersection-observer';
import { CMSEventParams, useAnalytics } from '@/app/hooks/useAnalytics';
import { useEffect, useRef } from 'react';
import { stripHtml } from '@/helpers/html';

export type UnknownProps = {
  [key: string]: string;
};

export interface CMSEventConfig {
  defaultEvent?: boolean;
  triggerOnce?: boolean;
}

interface Props {
  events: CMSEventParams[];
  eventsConfig?: CMSEventConfig;
  threshold?: number;
  componentProps: UnknownProps;
  children: React.ReactNode;
}

export function InViewEvents({
  events,
  eventsConfig,
  threshold = 0.2,
  children,
  componentProps,
}: Props) {
  const hasSentEvents = useRef(false);
  const { ref, inView } = useInView({
    threshold,
    triggerOnce: eventsConfig?.triggerOnce !== false,
  });

  const { sendEvents } = useAnalytics();

  useEffect(() => {
    if (!inView || hasSentEvents.current) return;

    hasSentEvents.current = true;

    // TODO(@gabrieldissotti): Create a test to check if there are a new dynamic component that does not have any of these props in type.
    function inferSectionReference(): string {
      try {
        if (!componentProps) {
          return '';
        }

        if ('title' in componentProps && componentProps.title) {
          return componentProps.title;
        }

        if ('testimonials' in componentProps) {
          const testimonials =
            componentProps.testimonials as unknown as UnknownProps;

          return testimonials.title;
        }

        if ('flagsCollectionType' in componentProps) {
          const flagsCollectionType =
            componentProps.flagsCollectionType as unknown as UnknownProps;

          return flagsCollectionType.title;
        }

        if ('cards' in componentProps) {
          const cards = componentProps.cards as unknown as UnknownProps[];

          return cards.map(card => card.title).join(', ');
        }

        if ('horizontalCards' in componentProps) {
          const cards =
            componentProps.horizontalCards as unknown as UnknownProps[];

          return cards.map(card => card.title).join(', ');
        }

        if ('infoCards' in componentProps) {
          const cards = componentProps.infoCards as unknown as UnknownProps[];

          return cards.map(card => card.title).join(', ');
        }

        if ('label' in componentProps) {
          return componentProps.label;
        }

        if ('referId' in componentProps) {
          return componentProps.referId;
        }

        if ('name' in componentProps) {
          return componentProps.name;
        }

        return '';
      } catch (error) {
        console.warn(error);

        return '';
      }
    }

    const sectionReference = stripHtml(inferSectionReference());
    const defaultFields = {
      name: 'section viewed',
      description: `Evento disparado ao visualizar a seção "${sectionReference}"`,
    };

    let cmsOrDefaultEvents = events || [];

    if (!events?.length && eventsConfig?.defaultEvent !== false) {
      cmsOrDefaultEvents = [defaultFields];
    }

    const eventsToSend = cmsOrDefaultEvents.map(event => ({
      ...event,
      name: event.name || defaultFields.name,
      description: event.description || defaultFields.description,
    }));

    if (eventsToSend.length) {
      sendEvents(eventsToSend, {
        section: sectionReference,
      });
    }

    if (eventsConfig?.triggerOnce === false) {
      hasSentEvents.current = false;
    }
  }, [
    componentProps,
    eventsConfig,
    eventsConfig?.defaultEvent,
    events,
    inView,
    sendEvents,
  ]);

  return <span ref={ref}>{children}</span>;
}
